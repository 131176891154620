<template>
  <div class="loading" v-if="loading">
    <p>Loading...</p>
    <div>
      <img width="80" src="/mule.gif" alt="Mule loading" />
    </div>
  </div>
  <div class="home" v-else-if="uid">
    <header>
      <h1>Mule</h1>
      <div class="login-details">
        <button @click="signOut">Log out</button>
        <span class="logged-in-as">Logged in as {{ email }}</span>
      </div>
    </header>
    <div class="page">
      <div v-if="imgSrc" class="image-wrap">
        <img :src="imgSrc" />
        <button class="delete-image" @click="deleteImage()">
          Delete image &times;
        </button>
      </div>
      <textarea
        v-else
        v-model="content"
        placeholder="Stick some text up your bum here, for retrieval on the other side."
      ></textarea>
    </div>
  </div>
  <div class="sign-in" v-else>
    <h1>Mule</h1>
    <p>A tiny website to pass text from one device to another.</p>
    <button @click="signIn">Sign in with Google</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { signIn, signOut } from "@/firebase/User.js";
import { signIn, signOut } from "@/firebase/User.js";
import { auth, db } from "@/firebase/App.js";
import { getRedirectResult } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const storage = getStorage();

export default {
  name: "App",
  data() {
    return {
      imgSrc: false,
      loading: true,
      packages: false,
      content: "",
      timeout: false,
      storageRef: null,
    };
  },
  watch: {
    // whenever question changes, this function will run
    content: function (newContent) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = false;
      }
      this.timeout = setTimeout(async () => {
        console.log("updating!");
        await setDoc(this.userRef, { content: newContent }, { merge: true });
        this.timeout = false;
      }, 1000);
    },
  },
  computed: {
    ...mapState(["uid", "email"]),
  },
  methods: {
    async deleteImage() {
      this.content = "";
      this.imgSrc = "";
      await deleteObject(this.storageRef);
      console.log("Image deleted");
    },
    async addDoc() {
      console.log("Adding doc!");
      await addDoc(collection(db, "users", this.uid, "packages"), {
        created_at: Date.now(),
        content: "",
      });
    },
    async signIn() {
      return await signIn();
    },
    async signOut() {
      return await signOut();
    },
    async updateContent(data) {
      if (data.content == this.content) {
        return;
      }
      this.content = data.content;
      if (data.content.indexOf("__FILE__") == 0) {
        let src = await getDownloadURL(ref(storage, this.uid));
        if (src.indexOf("?") >= 0) {
          src += "&d=" + Date.now();
        } else {
          src += "?d=" + Date.now();
        }
        this.imgSrc = src;
      } else {
        this.imgSrc = null;
      }
    },
    async load() {
      if (!this.uid) {
        this.loading = false;
        return;
      }
      this.userRef = doc(db, "users", this.uid);
      this.storageRef = ref(storage, this.uid);
      let userSnap = await getDoc(this.userRef);
      // If there is no user, create one with a content field
      if (!userSnap.exists()) {
        await setDoc(doc(collection(db, "users"), this.uid), {
          created_at: Date.now(),
          content: "",
        });
      }
      // await setDoc(this.userRef, { content: "" }, { merge: true });
      this.unsub = onSnapshot(this.userRef, (doc) => {
        this.updateContent(doc.data());
      });
      this.loading = false;
    },
    async loadImage(file) {
      var reader = new FileReader();
      reader.onload = async (e) => {
        this.imgSrc = e.target.result;
        console.log("now upload...");
        await uploadString(this.storageRef, this.imgSrc, "data_url");
        this.content = "__FILE__" + Date.now();
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    var IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;

    document.onpaste = (e) => {
      var items = e.clipboardData.items;
      for (var i = 0; i < items.length; i++) {
        if (IMAGE_MIME_REGEX.test(items[i].type)) {
          this.loadImage(items[i].getAsFile());
          e.preventDefault();
          return;
        }
      }
    };

    getRedirectResult(auth)
      .then((result) => {
        console.log(result, result.user);
        if (result.user) {
          this.commit("setUID", result.user.uid);
        }
      })
      .catch(() => {
        console.log("Not a redirect.");
      })
      .finally(() => {
        this.load();
      });
  },
  beforeUnmount() {
    this.unsub();
  },
};
</script>

<style lang="scss">
$background: #ffd670;
$highlight: #ffffff;
$hover: #ff70a6;

* {
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%;
  background: $background;
  margin: 0;
  padding: 0;
}

html,
body,
p,
input,
button,
textarea {
  font-family: "Rubik", sans-serif;
}

.loading,
.home,
.sign-in {
  height: 100%;
}
.loading,
.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 230px;
  margin: 0 auto;
  p {
    margin: 30px 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Chango", cursive;
}

.home {
  display: grid;
  grid-template-rows: auto 1fr;
}

header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
  h1 {
    margin: 0;
  }
}
.page {
  padding: 10px;
}

button,
input[type="submit"] {
  appearance: none;
  border: 1px solid #000000;
  border-radius: 20px;
  height: 40px;
  padding: 0 30px;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: $hover;
  }
}

textarea {
  height: 100%;
  width: 100%;
  resize: none;
  display: block;
  background: $highlight;
  border: none;
  border-radius: 2px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #000;
  &:focus {
    outline: none;
  }
}

//

.login-details {
  position: relative;
}
.logged-in-as {
  display: none;
  position: absolute;
  right: 0;
  top: 45px;
  background: #fff;
  padding: 10px 15px;
  box-shadow: 0 1px 3px 0 rgba(#000, 0.2);
  border-radius: 3px;
  border: 1px solid #000;
  border-radius: 20px;
  font-size: 0.8em;
}

.login-details:hover .logged-in-as {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-wrap {
  position: relative;
}
.delete-image {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
