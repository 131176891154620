import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBF9-H4oJvdr-v5fTJeaHmBsZQZhkSCNrc",
  authDomain: "mule-d8bc9.firebaseapp.com",
  projectId: "mule-d8bc9",
  storageBucket: "mule-d8bc9.appspot.com",
  messagingSenderId: "549966312310",
  appId: "1:549966312310:web:0419e245c8081d2a200057"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
