import { auth } from "./App.js";
import { GoogleAuthProvider, signInWithRedirect, onAuthStateChanged } from "firebase/auth";
import store from "@/store/index.js";

onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log(user);
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    store.commit("setUID", user.uid);
    store.commit("setEmail", user.email);
  } else {
    console.log("signed out")
    store.commit("setUID", null);
  }
});

export const signIn = async () => {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
};

export const signOut = async () => {
  auth.signOut();
}