import { createStore } from 'vuex'

export default createStore({
  state: {
    uid: null,
    email: null
  },
  mutations: {
    setUID(state, uid) {
      state.uid = uid;
    },
    setEmail(state, email) {
      state.email = email;
    },
  },
})
